@import "../utilities/utilities.scss";

.Events {

  .gallery {
    height: calc(100vh - 100px);
    position: relative;
    background: $orange-3;

    img {
      height: calc(100vh - 100px);
      object-fit: contain;
    }

    h1 {
      position: absolute;
      bottom: 50px;
      left: 20px;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(16px);
      padding: 16px;
      border-radius: 32px;
      font-size: 24px;
    }
  }

  .events-list {
    @include padding($sides: 40px);
    display: flex;
    flex-direction: column;
    gap: 50px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;

      img {
        height: 60vh;
        object-fit: contain;
        background: $orange-3;
      }

      h1 {
        font-size: 24px;
      }

    }
  }

  //@include padding($sides: 40px, $max-width: 1000px);
  //display: flex;
  //flex-direction: column;
  //gap: 50px;
  //
  //& > div {
  //  display: flex;
  //  gap: 16px;
  //  flex-direction: column;
  //
  //  .slide {
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //    background: $orange-3;
  //  }
  //
  //  .slide img {
  //    max-height: 500px;
  //    max-width: 100%;
  //    width: auto;
  //  }
  //
  //}

}