@import "../utilities/utilities.scss";

.Home {
  @include padding($sides: 40px, $max-width: 1000px);
  display: flex;
  flex-direction: column;
  gap: 50px;

  .daily-message {
    background: $orange-3;
    border-radius: 4px;
    padding: 8px 16px;
  }

  .origin {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-position: inside;
  }

  .structure {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .tree {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      div {
        padding: 8px;
        border-radius: 8px;
        display: inline-block;
        background: $orange-3;
        border-top: 4px solid $orange-6;
        font-size: 14px;
      }
    }
  }
}