@import "../../utilities/utilities.scss";

.Configurator {
  min-height: calc(100vh - 100px);
  background: $orange-1;

  .header {
    position: fixed;
    display: flex;
    top: 0;
    height: 50px;
    width: 100%;
    justify-content: space-between;
    background: $orange-2;
    @include padding(0, 0, 40px);
    box-sizing: border-box;

    h1 {
      font-size: 20px;
    }

    p {
      cursor: pointer;
      &:hover {
        font-weight: bold;
      }
    }

    h1, p {
      display: flex;
      align-items: center;
    }
  }

  .sidebar {
    position: fixed;
    background: $orange-2;
    min-height: calc(100vh - 50px);
    width: 200px;
    left: -175px;
    transition: left 250ms;
    z-index: 3;
    top: 50px;

    &:hover {
      left: 0;
    }

    p {
      padding: 8px 32px;
      cursor: pointer;

      &:hover, &.active {
        background: $orange-3;
      }
    }

    .arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .main {
    padding: 0 20px 20px 45px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100vh - 100px);
    box-sizing: border-box;

    .top {
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 20px;
      }
    }

    .table {
      .list {
        display: flex;
        flex-direction: column;
        gap: 4px;

        p {
          font-size: 12px;
          background: $orange-2;
          padding: 4px 8px;
        }
      }
    }

    .form {
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      z-index: 4;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(2px);
      box-sizing: border-box;
      padding: 30px;

      & > div {
        background: $orange-1;
        width: 90vw;
        max-width: 500px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        overflow-y: scroll;
        max-height: 100%;
        box-sizing: border-box;
        transform: translateX(-12.5px);

        .file-upload {
          display: flex;
          flex-direction: column;
          gap: 4px;

          & > p {
            font-size: 12px;
            color: $gray-7;
          }

          .file-list {
            padding: 16px;
            border: 1px solid $gray-6;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            #file {
              display: none;
            }

            div {
              display: flex;
              gap: 16px;
              align-items: center;
              justify-content: space-between;

              p {
                font-size: 14px;
              }

              img {
                width: 50px;
                height: 50px;
                object-fit: cover;
              }

              svg {
                fill: $gray-6;
                cursor: pointer;

                &:hover {
                  fill: $gray-8;
                }
              }
            }

          }
        }

        .form-actions {
          display: flex;
          gap: 16px;
        }
      }
    }
  }


}