@import "../../utilities/utilities.scss";

.Login {
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;

  h1 {
    font-size: 24px;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

}