@import "../utilities/utilities.scss";

.Footer {
  background: $orange-6;
  @include padding($sides: 40px, $max-width: 600px);
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;

  img {
    height: 75px;
    margin-left: -24px;
  }

  p, a, h6 {
    font-size: 14px;
  }

  .info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    a:hover, a.active {
      font-weight: bold;
    }

    .links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .contact {
      display: grid;
      grid-template-columns: 20px 1fr;
      justify-items: start;
      align-items: end;
      grid-gap: 0 16px;

      a {
        margin-top: 16px;
      }

      svg {
        font-size: 20px;
      }
    }
  }

  hr {
    width: 100%;
    border-bottom: 1px solid $orange-10;
  }

  @media (max-width: 800px) {
    .info {
      flex-direction: column;
      gap: 32px;
    }
  }

}