@import "utilities/utilities.scss";

@font-face {
    font-family: Poppins;
    src: url("fonts/Poppins-Regular.ttf");
}

* {
    border: 0;
    padding: 0;
    margin: 0;
    font-family: Poppins, sans-serif;
}

a {
    text-decoration: none;
    color: black;
    font-size: 14px;
}

ul {
    list-style-position: inside;
}

.App {
    background: $orange-1;
    padding-top: 100px;
}