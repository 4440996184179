@import "../utilities/utilities.scss";

.Header {
  @include padding(0, 0, 20px, 1200px);
  display: flex;
  justify-content: space-between;
  background: linear-gradient($orange-3, $orange-1);
  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 2;

  &.scroll {
    background: $orange-2;
  }

  .logo {
    display: flex;

    img {
      height: 100px;
      padding: 12.5px 0;
      box-sizing: border-box;
    }
  }

  .menu {
    display: none;
    margin-right: 40px;
    cursor: pointer;
  }

  .links {
    display: flex;

    a, .sub-menu {
      padding: 0 16px;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      position: relative;

      &:hover, &.active {
        font-weight: bold;
      }
      
      @media (min-width: 751px) {
        &.sub-menu {
          justify-content: center;

          // Hidden by default
          div {
            display: none;
          }

          //Show when hover
          &:hover {

            div {
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 100px;
              background: $orange-2;

              a {
                padding: 8px 16px;
                font-weight: normal;

                &:hover {
                  font-weight: bold;
                }
              }
            }
          }
        }

      }

    }

    .contact {
      display: none;
    }
  }

  // Collapse menu
  @media (max-width: 750px) {
    flex-direction: row;
    align-items: center;

    .menu {
      display: flex;
    }

    .links {
      position: fixed;
      top: 100px;
      right: -100vw;
      bottom: 0;
      flex-direction: column;
      background: $orange-7;
      padding: 40px;
      height: auto;
      width: 100vw;
      box-sizing: border-box;
      gap: 16px;
      align-items: flex-end;
      transition: right 500ms;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      a, p {
        color: white;
      }

      .sub-menu {
        padding: 0;

        &:hover {
          font-weight: normal;
        }

        p {
          display: none;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 16px;
        }
      }

      .contact {
        display: grid;
        grid-template-columns: 20px 1fr;
        padding-top: 32px;
        grid-gap: 0 16px;
        align-items: end;
        justify-items: start;
        color: white;
        margin-top: auto;

        svg {
          font-size: 20px;
        }

        a, p {
          padding: 0;
          font-size: 14px;
        }

        a {
          margin-top: 16px;
        }
      }

      &.open {
        right: 0;
      }

    }
  }

}