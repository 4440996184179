@import "../utilities/utilities.scss";

.Products {
  @include padding($sides: 40px, $max-width: 1000px);
  display: flex;
  flex-direction: column;
  gap: 50px;

  .intro {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > div {
      display: flex;
      gap: 32px;
      align-items: flex-start;

      img {
        width: 400px;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .product-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    & > div {
      display: flex;
      gap: 16px;
      align-items: center;
      background: $orange-3;
      padding: 16px;
      transition: background 500ms;

      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }

      &:hover {
        background: $orange-4;
      }

      img {
        width: 300px;
        border-radius: 50%;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
      }
    }
  }

  @media (max-width: 800px) {
    .intro > div {
      flex-direction: column;

      img {
        width: 100%;
      }
    }

    .product-list > div {
      flex-direction: column !important;

      img {
        width: 100%;
        max-width: 400px;
      }
    }
  }

}