@import "../utilities/utilities.scss";

.About {
  @include padding($sides: 40px, $max-width: 1000px);
  display: flex;
  flex-direction: column;
  gap: 50px;

  .development {
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    gap: 32px;

    & > div {
      display: flex;
      gap: 40px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .about-us {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 32px;

    div {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    h1 {
      grid-column: 1 / 3;
    }
  }
  
  @media (max-width: 800px) {
    .development > div {
      flex-direction: column;
      gap: 16px;
    }
    .about-us {
      grid-template-columns: 1fr;
      gap: 32px;
      h1 {
        grid-column: 1 / 2;
      }
    }
  }

}